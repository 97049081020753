<template>
  <v-row justify="center">
    <v-col sm="10">
      <v-card>
        <v-card-title>
          {{ $t('errorDashboard.table.title') }}
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('errorDashboard.table.search')"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :search="search"
          :item-class="itemRowBackground"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <v-btn
              block
              class="mt-2 mb-2"
              color="primary"
              elevation="2"
              @click="$router.push({ name: 'ErrorDetailCar', params: { id: item.id }})"
            >
              {{ $t('errorDashboard.table.action') }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      search: '',
      headers: [
        { text: 'Typ', value: 'type' },
        {
          text: 'Pod typ',
          align: 'start',
          sortable: false,
          value: 'subtype'
        },
        // { text: this.$t('errorDashboard.table.header.claim'), value: 'claim' },
        { text: this.$t('errorDashboard.table.header.description'), value: 'description' },
        { text: this.$t('errorDashboard.table.header.action'), value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    itemRowBackground (item) {
      if (item.maintainers) {
        return 'highlight'
      } else {
        return item.claim === this.$t('errorDashboard.no') ? 'claimed' : ''
      }
    }
  }
}
</script>

<style lang="scss">
.claimed {
  background: whitesmoke;
}

.highlight {
  background: orange;
}
</style>
