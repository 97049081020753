<template>
  <v-row
    class="text-center"
  >
    <v-col cols="12">
      <h1>{{ $t('errorDashboard.title') }}</h1>
      <v-spacer />
      <error-table :table-data="errors" />
    </v-col>
    <v-col
      v-if="getUserData.role === 'ADMIN'"

      class="mb-6"
      cols="12"
    >
      <v-btn
        x-large
        color="primary"
        @click="downloadFile"
      >
        {{ $t('errorDashboard.download') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrorTable from './ErrorTable'
import downloadCSV from '@/helpers/downloadCSV'

export default {
  name: 'ErrorDashboard',
  components: {
    ErrorTable
  },
  data () {
    return {
      errors: []
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const response = await this.$axios.get('/car-errors/')
        const saveData = response.data.map(task => ({
          id: task.id,
          type: task.carType.name,
          subtype: task.carType.subType ? task.carType.subType : 'Neuvedený',
          claim: task.canBeClaimed ? 'Áno' : 'Nie',
          description: task.description,
          maintainers: !!(task.neededMaintainers > 1 && task.canBeClaimed)
        }))
        this.errors = saveData
      } catch (err) {
        console.error(err)
      }
    },
    downloadFile () {
      downloadCSV(this.errors, 'data.csv')
    }
  }
}
</script>
